import React from "react";
import Spacer from "../../shared/Components/Spacer";
import Moedas from "../../assets/moedas.svg";
import "./styles.css";
import ButtonFrotabank from "../../shared/Components/ButtonFrotaBank";
import { useNavigate, useLocation } from "react-router-dom";

function SaldoEnviado() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const val_resgate =
    state?.val_resgate?.toFixed(2)?.replace(".", ",") || "00.00";

  return (
    <div className="container">
      <Spacer />
      <div
        onClick={() => {
          navigate("/extratoResgate");
        }
        }
        className="x-icon"
      >
        X
      </div>
      <div className="title">
        <label>
          Agendamento de<br />
          transferência para<br />
          o Frotabank
        </label>
      </div>
      <img src={Moedas} alt="moedas"></img>
      <div style={{ fontSize: "25px" }} className="title">
        <label>R$ {val_resgate}</label>
        <Spacer margin="10px" />
        <div className="subtitle column">
          <label>{state?.situacao || "Em processamento"}</label>
        </div>
        <Spacer />

      </div>
      <Spacer />
      <ButtonFrotabank
        bgColor="#C4C4C4"
        title="Ver extrato"
        color="black"
        width="60%"
        onClick={() => navigate("/extratoResgate")}
      />
    </div>
  );
}

export default SaldoEnviado;
