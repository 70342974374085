import { format, isValid, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import moment from 'moment'


export const dateFormatter = (
    rawDate,
    formatAs = 'Pp',
    invalidDateString = '-',
  ) => {
    
    const newDate = moment(rawDate, 'YYYY-MM-DD').format('YYYY-MM-DD');

    let parsedDate = newDate;

    if (typeof newDate === 'string') {
      parsedDate = parseISO(newDate);
    }
    
    const isValidDate = isValid(parsedDate);
  
    if (!isValidDate) {
      return invalidDateString;
    }
  
    const formattedDate = format(parsedDate, formatAs, { locale: ptBR });

    return formattedDate;
  };

  export const currencyFormatter = value => {
    const rawValue = value ?? 0;
  
    return Intl.NumberFormat('pt-br', {
      style: 'currency',
      currency: 'BRL',
    }).format(rawValue);
  };

  export const reformatCurrency = value => {
    const hasNumbersRegex = /\d/;
  
    if (!value || !hasNumbersRegex.test(value)) {
      return 0;
    }
  
    const parsedValue = parseFloat(
      String(value)
        .replace(/[R$ .]/g, '')
        .replace(',', '.'),
    );
  
    return parsedValue;
  };