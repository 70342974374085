import './styles.css';
import PegaPontosSection from '../../shared/Components/PegapontosSection/index'

import React, { useEffect, useState } from 'react';
import ButtonFrotaBank from '../../shared/Components/ButtonFrotaBank';
import InputFrotabank from '../../shared/Components/InputFrotabank';
import Spacer from '../../shared/Components/Spacer';
import { useNavigate } from 'react-router-dom';
import { getSaldoMembroPegaPontos } from '../../services/fetchService';
import openNotification from '../../shared/utils/openNotification';
import { VscWarning } from 'react-icons/vsc';
import AuthError from '../../shared/Components/AuthError';
import { getCookieValue } from '../../shared/utils/cookieService';
import Loading from '../../shared/Components/Loading';
import {
  fetchEmbaixadorData,
  GetLimitesResgate
} from "../../services/fetchService";
import { AiOutlineLeft } from 'react-icons/ai';

function TrocarPontos() {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [pontosPegapontos, setPontosPegapontos] = useState(0);
  const [pontosTrocar,setPontosTrocar] = useState(0);
  const [valor,setValor] = useState(0.0);
  const [multiplicadorPontos, setMultiplicadorPontos] = useState(0.0);
  const [authError, setAuthError] = useState(false);
  const [limiteMinimo, setlimiteMinimo] = useState("");
  const [aviso, setAviso] = useState("");

  useEffect(() => {

    async function fetchData() {

        if(isFetching){
            try{
          const userCpf = getCookieValue('user');
          const token = getCookieValue('authToken');


          const authToken = getCookieValue("authToken");
          const cpf = getCookieValue("user");

          if(token === null){
            setAuthError(true);
            return;
          }
             
          const embaixador = await fetchEmbaixadorData(cpf, authToken).catch(
            (err) => setAuthError(true)
          );

           const limiteMinimoResponse = await GetLimitesResgate(token, cpf).catch(
             (err) => setAuthError(true)
          );
          const {limiteMinimoAtivo, limiteMinimo} = limiteMinimoResponse.data;
          if(limiteMinimoAtivo){
              setlimiteMinimo(`Quantidade mínima: ${limiteMinimo} pontos`);
          }




          const multiplicador = embaixador.data.val_pegapontos;

          setMultiplicadorPontos(multiplicador);

const membroResponse = await getSaldoMembroPegaPontos(userCpf,token).catch(err => setAuthError(true));
          const pontos = membroResponse.data.saldo.saldoPontos;
          const somaPontos = pontos.reduce((acumulador, saldo) => {
            return acumulador += saldo.saldoPontos;
          }, 0);

          setPontosPegapontos(somaPontos);
                    
            }catch(e){
                setAuthError(true);
                console.log(e);
            }finally {
                setIsLoading(false);
            }
      }
    }

    fetchData();

    setIsFetching(false);
  }, [isFetching, isLoading, pontosPegapontos]);

  function handlePontosChange(e) {
    var valor = e.target.value;
    var numeroInteiro = parseInt(valor);

    if (isNaN(numeroInteiro) || valor.includes('.') || valor.includes(',')) {
      e.target.value = valor.slice(0, -1);
      setAviso('Por favor, insira somente números inteiros.');
    } else {
      setAviso('');
    }

    setPontosTrocar(valor);
    if(multiplicadorPontos !== null) {
      let valorTotal = valor * multiplicadorPontos;
      setValor(valorTotal.toFixed(2));
    }
  }

  function handleContinuar(){
    const icon = <VscWarning style={{color: 'red'}} />
    if(pontosTrocar > pontosPegapontos){
      openNotification('alertaPontos', 'Quantidade de pontos excedidos', 'Você não possui essa quantidade de pontos', icon)
    } 
    else {

      if(pontosTrocar <= 0 || pontosTrocar === null || pontosTrocar === undefined){
        openNotification('Sem pontos!', 'Quantidade inválida', 'É necessário informar uma quantidade de pontos maior que 0', icon);
      }
      else{
        navigate('/confirmacaoTrocaPontos', { state: { valor, pontosTrocar } });
      }
    }
  }

  return (
    <>
      {!authError ? 
        isLoading ? 
          <Loading />
          :
          <div className="container center">
          <div style={{fontSize: '2rem',lineHeight: '2.3rem', marginTop: '10%'}} className="title">
            Você pode converter seu saldo <br/> de pontos em dinheiro na conta <br/> Frotabank
            <PegaPontosSection qntdPontos={pontosPegapontos}/>
              <ButtonFrotaBank title="Ver extrato de venda de pontos" onClick={() => { navigate('/extratoResgate'); }} />
              <Spacer margin="40px"/>
              <div className="subtitle2 center">
                <label>Quantos pontos você quer resgastar?</label>
              </div>
              <InputFrotabank
                type="number"
                placeholder="Qtd. pontos"
                disabled = {pontosPegapontos <= 0}
                onChange={handlePontosChange}
                maxNumber={pontosPegapontos || 0}
              />
              <div style={{ fontSize: '9px', float: 'left', marginLeft: '5px', color: '#037983'}}>
              {aviso ? aviso : limiteMinimo}
            </div>
            <div style={{fontSize: '50px',marginTop: '19px', marginBottom: '20px'}}>=</div>
            <InputFrotabank
              type="number" 
              disabled 
              placeholder= "R$ 0,00" 
              value={valor}
              />
            <Spacer margin="25px"/>
            <ButtonFrotaBank 
              color={pontosTrocar === 0 ? '#C4C4C4' : 'white'} 
              disabled={pontosTrocar === 0} 
              width="60%" 
              title="Continuar" 
              onClick={handleContinuar}/>

            <Spacer margin="40px"/>

            <button id="btn-anterior" 
              onClick={() =>{
                const cpf = getCookieValue('user');
                const token = getCookieValue('tokenv');
                navigate(`/?cpf=${cpf}&token=${token}`);
              }
              }>
              <AiOutlineLeft className="icon-voltar"/>Anterior
            </button>
            </div>
          </div>
        : <AuthError />
      }
    </>
  );
}

export default TrocarPontos;