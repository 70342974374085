import React from 'react'
import Spacer from '../../shared/Components/Spacer'
import LogoPegaPontos from '../../assets/logo-pegapontos.svg'
import './styles.css'
import { useNavigate, useLocation } from 'react-router-dom';
import ButtonFrotaBank from '../../shared/Components/ButtonFrotaBank'
import ButtonAnterior from '../../shared/Components/ButtonAnterior'

function SaldoEnviado() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const registerDate = state?.date ?? '00/00/0000'
  const registerTime = state?.time ?? '00:00'
  const val_resgate = state?.val_resgate?.toFixed(0)

  function handleTrocarPontos() {
    navigate('/trocarPontos', { state: state?.val_pegapontos });
  }

  return (
    <div className="container">
      <Spacer />
      <div
        onClick={() => navigate(-1)}
        className="x-icon"
      >
        X
      </div>
      <div className="title" style={{ marginBottom: 0 }}>
        <label>
          Você ganhou pontos <br /> do Pegapontos
        </label>
      </div>
      <img
        style={{ width: '40%', margin: '0 30px 10px 30px' }}
        src={LogoPegaPontos}
        alt="moedas"
      ></img>
      <div style={{ fontSize: '23px' }} className="title">
        <label>{val_resgate} pontos</label>
        <Spacer margin="10px" />
        <div className="subtitle column">
          <label>{`${registerDate} às ${registerTime}`}</label>
        </div>

        <div
          className="subtitle column"
          style={{ margin: '50px 0px 20px 0px' }}
        >
          <label>
            Você pode usá-los nas ofertas do Pegapontos, ou converter seus
            pontos em dinheiro.{' '}
          </label>
        </div>
      </div>

      <ButtonFrotaBank
        title="Converter pontos em dinheiro"
        onClick={handleTrocarPontos}
        margin="0 10px 10px"
      />
      <div>
        <ButtonAnterior />
      </div>

    </div>
  )
}

export default SaldoEnviado
