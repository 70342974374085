import React from "react";
import { Spin } from "antd";
import "./styles.css";

function Loading() {
  return (
    <div className="spin-center">
      <Spin />
    </div>
  );
}

export default Loading;
