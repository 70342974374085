import React from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import './styles.css';

function ButtonAnterior() {
  const navigate = useNavigate();

  return (
    <button id="btn-anterior" onClick={() => navigate(-1)}>
      <AiOutlineLeft className="icon-voltar" />Anterior
    </button>
  );
}

export default ButtonAnterior;
