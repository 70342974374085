import React, { useEffect, useState } from "react";

import { Spin } from "antd";
import ButtonFrotabank from "../../shared/Components/ButtonFrotaBank";
import ButtonAnterior from "../../shared/Components/ButtonAnterior";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Spacer from "../../shared/Components/Spacer";
import AuthError from "../../shared/Components/AuthError";
import * as yup from "yup";
import "./styles.css";
import {
  cadastrarMembroPegaPontos,
  fetchEmbaixadorData,
} from "../../services/fetchService";
import moment from "moment";
import ModalFrotabank from "../../shared/Components/ModalFrotabank";
import InputMask from "react-input-mask";
import openNotification from "../../shared/utils/openNotification";
import { LoadingOutlined } from "@ant-design/icons";
import { getCookieValue } from "../../shared/utils/cookieService";
import Loading from "../../shared/Components/Loading";
import { dateFormatter } from "../../utils/formaters";

function CadastroPegaPontos() {
  const [embaixador, setEmbaixador] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [loadPage, setLoadPage] = useState(true);
  const [dataNascimento, setDataNascimento] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [authError, setAuthError] = useState(false);

  const loadingComponent = <LoadingOutlined style={{ fontSize: 25 }} spin />;

  useEffect(() => {
    async function getData() {
      if (loadPage) {
        try {
          const authToken = getCookieValue("authToken");

          if (authToken === null) {
            setAuthError(true);
            return;
          }

          const cpf = getCookieValue("user");

          const embaixadorResponse = await fetchEmbaixadorData(
            cpf,
            authToken
          ).catch(() => setAuthError(true));

          if (embaixadorResponse.status === 200) {
            setEmbaixador(embaixadorResponse.data);
          }
          setDataNascimento(
            moment(
              embaixadorResponse.data?.dta_nasc?.split(" ")[0],
              "MM-DD-YYYY"
            ).format("YYYY-MM-DD")
          );
        } catch (error) {
          console.log(error);
          alert("Aconteceu algum erro, tente novamente mais tarde!");
        }
      }
    }

    getData();
    setLoadPage(false);
  }, [loadPage, embaixador, dataNascimento]);

  function toggleModal() {
    setIsModalVisible(!isModalVisible);
  }

  function validarCpf() {
    return this.matches(
      /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}/,
      "CPF inválido"
    );
  }

  const content = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <iframe
        title="Termos de uso Pegapontos"
        style={{ height: "600px", width: "100%" }}
        src="https://www.pegapontos.com.br/termousomembro"
      ></iframe>
    </div>
  );

  yup.addMethod(yup.string, "validarCpf", validarCpf);
  const schema = yup.object().shape({
    nome: yup.string().required("É necessário informar seu nome"),
    cpf: yup.string().validarCpf().required("É necessário informar seu cpf"),
    dataNascimento: yup
      .string()
      .required("É necessário informar a data de nascimento")
      .nullable(true),
    email: yup.string().email("É necessário informar o e-mail").required(),
    celular: yup.string().required("É necessário informar seu whatsapp"),
    aceitouTermos: yup
      .bool()
      .oneOf([true], "É necessário aceitar os termos de uso"),
  });
  const cpf = getCookieValue("user");

  const onSubmit = async (values) => {
    console.log(values, "values");
    if (!values.celular || values.celular.endsWith("_")) {
      openNotification(
        "celular",
        "Celular inválido",
        "Digite um número de celular válido"
      );
    } else {
      const data = {
        nomeMembro: values.nome,
        celularMembro: values.celular,
        emailMembro: values.email,
        cpfCnpj: values.cpf,
        dataNascimento: dateFormatter(values.dataNascimento, "yyyy-MM-dd"),
        aceitouTermos: values.aceitouTermos,
      };

      try {
        setIsFetching(true);
        const authToken = getCookieValue("authToken");
        const pegaPontosCadastroResponse = await cadastrarMembroPegaPontos(
          data,
          authToken
        );
        if (pegaPontosCadastroResponse.resultado === 1) {
          openNotification(
            "success",
            "Cadastrado com sucesso!",
            "Seu cadastro foi feito com sucesso"
          );
          window.location.reload();
        } else {
          openNotification(
            "falha",
            "Falha no cadastro",
            pegaPontosCadastroResponse.mensagem
          );
        }
      } catch (error) {
        openNotification(
          "erro",
          "Aconteceu algum erro!",
          "Tente novamente mais tarde!"
        );
      } finally {
        setIsFetching(false);
      }
    }
  };

  const footer = (
    <>
      <button
        className="btn-modal"
        style={{
          backgroundColor: "#4AB8A8",
          color: "white",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
        key="Okbtn"
        onClick={() => setIsModalVisible(false)}
      >
        Ok
      </button>
    </>
  );

  return (
    <>
      {loadPage ? (
        <Loading />
      ) : !authError ? (
        <div className="container-cadastro">
          <ModalFrotabank
            title="Termos de uso do Pegapontos"
            isModalVisible={isModalVisible}
            handleOk={toggleModal}
            width="90%"
            content={content}
            footer={footer}
          />
          <div className="title-container-cadastro">
            <div>
              <label className="title-cadastro">
                Faça seu cadastro no <br />
                Pegapontos para acessar <br />
                suas recompensas
              </label>
            </div>
          </div>
          <Formik
            initialValues={{
              nome: embaixador.nome || "",
              cpf: cpf || undefined,
              dataNascimento: dataNascimento || "",
              email: embaixador.email || "",
              celular: embaixador.telefone || undefined,
              aceitouTermos: false,
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form className="form-group">
                <Field
                  type="text"
                  name="nome"
                  placeholder="Nome completo"
                  className="input-frotabank"
                />
                <ErrorMessage name="nome" className="error" component="div" />
                <Spacer margin="10px" />

                <Field name="cpf" className="input-frotabank">
                  {({ field, form, meta }) => (
                    <InputMask
                      {...field}
                      name="cpf"
                      placeholder="Seu CPF ou CNPJ"
                      className="input-frotabank"
                      mask="99999999999"
                    />
                  )}
                </Field>
                <ErrorMessage name="cpf" className="error" component="div" />

                <Spacer margin="10px" />
                <Field name="dataNascimento" className="input-frotabank">
                  {({ field, form, meta }) => (
                    <InputMask
                      {...field}
                      name="dataNascimento"
                      placeholder="Data nascimento"
                      className="input-frotabank"
                      mask="99-99-9999"
                    />
                  )}
                </Field>

                <ErrorMessage
                  name="dataNascimento"
                  className="error"
                  component="div"
                />

                {/* <Spacer margin="10px" />
                <Field
                  type="date"
                  //onFocus={()=> this.type = 'date'}
                  name="dataNascimento"
                  className="input-frotabank"
                  placeholder="Data nascimento"
                //initialValues="dataNascimento"
                  values={dataNascimento}
                /> */}

                <Spacer margin="10px" />

                <Field
                  type="email"
                  name="email"
                  className="input-frotabank"
                  placeholder="Seu e-mail"
                />
                <Spacer margin="10px" />
                <Field name="celular">
                  {({ field, form, meta }) => (
                    <InputMask
                      {...field}
                      name="celular"
                      placeholder="Celular (whatsapp)"
                      className="input-frotabank"
                      mask="99 9 99999999"
                    />
                  )}
                </Field>

                <ErrorMessage
                  name="celular"
                  className="error"
                  component="div"
                />
                <Spacer margin="10px" />
                <div className="termos-container">
                  <Field type="checkbox" name="aceitouTermos" />
                  <label>
                    Aceito os{" "}
                    <button
                      onClick={toggleModal}
                      type="button"
                      className="btn-termos-de-uso"
                    >
                      termos de uso
                    </button>
                  </label>
                </div>
                <ErrorMessage
                  name="aceitouTermos"
                  className="error"
                  component="div"
                />
                <Spacer />
                {!isFetching ? (
                  <ButtonFrotabank
                    type="submit"
                    width="60%"
                    title="Continuar"
                  />
                ) : (
                  <ButtonFrotabank
                    type="button"
                    grey
                    width="60%"
                    title={
                      <Spin
                        indicator={loadingComponent}
                        style={{ alignContent: "center" }}
                      />
                    }
                  />
                )}
              </Form>
            )}
          </Formik>
          <Spacer margin="30px" />
          <ButtonAnterior />
          <br></br>
          <Spacer margin="110px" />
          <div className="img-texts">
            <li>Versão {process.env.REACT_APP_BUILD_NUMBER}</li>
          </div>
        </div>
      ) : (
        <AuthError />
      )}
    </>
  );
}

export default CadastroPegaPontos;
