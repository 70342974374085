import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineLeft } from 'react-icons/ai';
import './styles.css';

function DinheiroBonus() {
    const navigate = useNavigate();

    function goBack() {
        navigate(-1);
    }

    return (
        <div className="container saldo-conta-geral-container">
            <div className="saldo-frotabank-text">
                Saldo na conta Frotabank
            </div>
            <div className="saldo-container">
                <label className="subtitle">Bônus de indicação<br /></label>
                <label className="subtitle">23/09/2021 às 09:31</label>
                <label className="saldo-frotabank-value">R$ 10,00<br /></label>
                <label className="subtitle">Em processamento</label>
            </div>
            <button onClick={goBack} className="btn-voltar">
                <AiOutlineLeft /> Anterior
            </button>
        </div>
    );
}

export default DinheiroBonus;
