import React from "react";
import Spacer from "../../shared/Components/Spacer";
import ButtonFrotabank from "../../shared/Components/ButtonFrotaBank";
import RostoTriste from '../../assets/rosto-triste.svg';
import { getCookieValue } from "../../shared/utils/cookieService";
import "./styles.css";
import { useLocation, useNavigate } from "react-router-dom";

function BlockedAccount() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const token = getCookieValue('tokenv');
  const cpf = getCookieValue('user');

  return (
    <div className="container">
      <Spacer />
      <div className="title">
        <label>
          Não foi possível trocar seus pontos por dinheiro<br />
        </label>
      </div>

      <img src={RostoTriste} alt="RostoTriste" />

      <div style={{ fontSize: "25px" }} className="title">
        <Spacer margin="10px" />
        <div className="subtitle column">
          <label style={{ textAlign: 'justify' }}>{state?.mensagem}</label>
        </div>
        <Spacer />
      </div>

      <Spacer />

      <ButtonFrotabank
        onClick={() => navigate(`/?cpf=${cpf}&token=${token}`)}
        width="60%"
        title="Entendi"
      />
    </div>
  );
}

export default BlockedAccount;
