import React from 'react';
import Spacer from '../../../../shared/Components/Spacer';
import ButtonFrotaBank from '../../../../shared/Components/ButtonFrotaBank';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import openNotification from '../../../../shared/utils/openNotification';
import './styles.css';

const situacaoMap = {
  "Cancelado": "Cancelado",
  "Pendente": "Em processamento",
  "Efetuado": "Confirmado"
};

function ExtratoResgateContainer({ extrato }) {
  const navigate = useNavigate();
  const subtitle = situacaoMap[extrato.situacao] || "Desconhecido";
  const registerDate = moment(extrato.dta_registro).format('DD/MM/yyyy');
  const registerTime = moment(extrato.dta_registro).format('HH:mm');

  const isCanceled = extrato.situacao === 'Cancelado';

  function handleComprovante() {
    if (extrato.situacao === 'Pendente') {
      openNotification(extrato.dta_resgate, 'Comprovante indisponível', `Agendamento de transferência para o Frotabank realizado. Em processamento.`);
    }
    else {
      navigate('/saldoEnviadoReais', {
        state: {
          registerDate,
          registerTime,
          val_resgate: parseFloat(extrato.val_resgate),
          situacao: extrato.situacao
        }
      });
    }
  }

  return (
    <div className="content-container">
      <div className="extrato-data">
        <label>Valor: {Number(extrato.val_resgate).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        })}</label>
        <label className="subtitle">{subtitle}</label>


      </div>
      <div className="right-side-extrato-resgate">
        <label
          style={{ fontWeight: 'bold', color: 'black' }}
          className="subtitle">
          {extrato.qtd || 0} pontos
        </label>
        {!isCanceled && (
          <>
            <Spacer margin="4px" />
            <ButtonFrotaBank
              width="100%"
              className="btn-comprovante"
              title="Comprovante"
              bgColor={extrato.situacao === "Pendente" && "#C4C4C4"}
              mini
              onClick={handleComprovante}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default ExtratoResgateContainer;