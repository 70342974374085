import React from 'react';
import './styles.css';

function InputFrotabank({readOnly, disabled,placeholder,onChange,value,type, maxNumber}) {
  return (
    <input 
      readOnly={readOnly}
      value={value}
      onChange={onChange} 
      disabled={disabled} 
      className="input-frotabank" 
      placeholder={placeholder}
      type={type}
      max={maxNumber}
      min={0}
      >
      
    </input>
  );
}

export default InputFrotabank;