import React from 'react';
import './styles.css';
import porcentagem from '../../assets/50porcento.svg';
import ButtonAnterior from '../../shared/Components/ButtonAnterior'
import Spacer from '../../shared/Components/Spacer'

function EmEspera() {

    return (
        <div className="container" >
            <Spacer />
            <div className="top-title-container">
                <label className="title">Falta pouco para você resgatar seu bônus.</label>
                <label className="sub-title">Seu amigo se cadastrou no seu link mas ainda
                    não abasteceu o valor mínimo de R$ 100 com cartão de débito Frotabank em
                    posto de combustível.</label>
            </div>
            <img src={porcentagem} alt="50% completo" />
            <div className="bottom-title-container">
                <label className="subtitle bottom-title">É necessário que seu amigo realize
                    o primeiro abastecimento com o cartão de débito acima
                    de R$ 100,00 para que você receba seus pontos. Indicação expira com 120 dias.</label>
            </div>
            <Spacer margin='30px' />
            <ButtonAnterior />
        </div>
    );
}

export default EmEspera;
