import React, { useEffect, useState } from "react";
import ConvidadoContainer from "./Components/ConvidadoContainer/index";
import Spacer from "../../shared/Components/Spacer";
import AuthError from "../../shared/Components/AuthError";
import { useLocation } from "react-router-dom";
import "./styles.css";

import ButtonAnterior from "../../shared/Components/ButtonAnterior";
import { getCookieValue } from "../../shared/utils/cookieService";

function ExtratoRecompensa() {
  const location = useLocation();
  const [indicacoes, setIndicacoes] = useState([]);
  const [authError, setAuthError] = useState(false);
  
  useEffect(() => {
    const authToken = getCookieValue('authToken');

    if (authToken === null) {
      setAuthError(true);
      return;
    }

    if (location.state && location.state.indicacoes) {
      setIndicacoes(location.state.indicacoes);
    }
  }, [indicacoes, location.state]);
  
  return (
    <>
      {!authError ? (
        <div className="container">
          {indicacoes.map((indicacao) => (
            <ConvidadoContainer
              key={indicacao.data}
              indicacao={indicacao}
              pontos={indicacao.total}
              val_pegapontos={location.state?.val_pegapontos}
            />
          ))}
          <Spacer />
          <ButtonAnterior />
        </div>
      ) : (
        <AuthError />
      )}
    </>
  );
}

export default ExtratoRecompensa;
