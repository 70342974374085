import { decrypt, encrypt } from "./crypto"

export const setCookie = function(name,value){
  document.cookie = `${name}=${encrypt(value)}`;
}

export const getCookieValue = function(cookieName) {
    let name = cookieName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return decrypt(c.substring(name.length, c.length));
      }
    }
    return null;
  }
