import { Modal } from 'antd'

const ModalFrotabank = (props) => {

  
    return (
      <>
        <Modal 
            title={props.title}
            visible={props.isModalVisible}
            onOk={props.handleOk}
            width={props.width}
            onCancel={ props.handleOk }
            footer={props.footer ?? null }
            >
           {props.content}
        </Modal>
      </>
    );
  };

  export default ModalFrotabank;