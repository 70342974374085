import React from 'react';
import { BrowserRouter, Routes as RouterRoutes, Route } from 'react-router-dom';
// import ConvidarAmigo from './pages/ConvidarAmigo/index';
// import HomeIndicacao from './pages/HomeIndicacao/index';
import ExtratoRecompensa from './pages/ExtratoRecompensa/index';
import BonusIndicacao from './pages/BonusIndicacao/index';
import EmEspera from './pages/EmEspera/index';
import ResgatarBonus from './pages/ResgatarBonus/index';
import TrocarPontos from './pages/TrocarPontos';
import ConfirmacaoTrocaPontos from './pages/ConfirmacaoTrocaPontos';
import SaldoEnviado from './pages/SaldoEnviado';
import BlockedAccount from './pages/BlockedAccount';
import ExtratoResgate from './pages/ExtratoResgate';
import RecompensaPontos from './pages/RecompensaPontos';
import CadastroPegapontos from './pages/CadastroPegaPontos';
import Wrapper from './pages/Wrapper';
import SaldoEnviadoReais from './pages/SaldoEnviadoReais';

export default function Routes() {
  return (
    <BrowserRouter>
      <RouterRoutes>
        {/* <Route path="/" element={<ConvidarAmigo />} /> */}
        <Route path="/" element={<Wrapper />} />
        <Route path="/extratoRecompensa" element={<ExtratoRecompensa />} />
        <Route path="/bonusIndicacao" element={<BonusIndicacao />} />
        <Route path="/emEspera" element={<EmEspera />} />
        <Route path="/resgatarBonus" element={<ResgatarBonus />} />
        <Route path="/trocarPontos" element={<TrocarPontos />} />
        <Route path="/confirmacaoTrocaPontos" element={<ConfirmacaoTrocaPontos />} />
        <Route path="/saldoEnviado" element={<SaldoEnviado />} />
        <Route path="/blockedAccount" element={<BlockedAccount />} />
        <Route path="/extratoResgate" element={<ExtratoResgate />} />
        <Route path="/recompensaPontos" element={<RecompensaPontos />} />
        <Route path="/cadastroPegapontos" element={<CadastroPegapontos />} />
        <Route path="/saldoEnviadoReais" element={<SaldoEnviadoReais />} />
      </RouterRoutes>
    </BrowserRouter>
  );
}
