import { notification } from 'antd';

export default function openNotification(key, message,description, icon) {
  notification.open({
    key: key,
    message: message,
    description: description,
    icon: icon || undefined,
    onClick: () => {
      notification.close(key);
    },
  });
};