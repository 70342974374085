import React from 'react';
import logoPegaPontos from '../../../assets/pegapontos-logo.svg';
import './styles.css'
import moment from 'moment';

function PegapontosSection({qntdPontos, dataProcessoPegaPontos}) {
  return (
    <div className="pegapontos-section">
        <div className="left-side-container">
            <label>Seu saldo:</label>
            <img src={logoPegaPontos} alt="logo pegapontos"></img>
        </div>
        <div className="right-side-container">
          <label className="pegapontos-saldo">{qntdPontos || 0}<span>Pontos</span></label>
          <div style={{ fontSize: '9px' }}>{`Atualizado em ${moment(dataProcessoPegaPontos).format('DD/MM/YYYY HH:mm')}`}</div>
        </div>
    </div>
  );
}

export default PegapontosSection;