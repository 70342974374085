import React from 'react'
import './styles.css'
import { LoadingOutlined } from '@ant-design/icons'

function ButtonFrotaBank(props) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  return (
    <>
      {!props.mini ? (
        <button
          type={props.type}
          disabled={props.disabled}
          style={{
            width: props.width ?? '90%',
            backgroundColor: props.bgColor,
            alignSelf: 'center',
            color: props.color,
            fontSize: props.fontSize ?? '1.6rem',
            margin: props.margin,
            pointerEvents: props.disableClick ? 'none' : 'auto',
          }}
          className="button-fb"
          onClick={props.onClick}
        >
          {!props.isLoading ? props.title : antIcon}
        </button>
      ) : (
        <button
          style={{
            width: props.width ?? '90%',
            backgroundColor: props.bgColor,
            color: props.color,
            alignSelf: 'center',
            pointerEvents: props.disableClick ? 'none' : 'auto',
          }}
          className="mini-fb"
          disabled={props.disabled}
          onClick={props.onClick}
        >
          {!props.isLoading ? props.title : antIcon}
        </button>
      )}
    </>
  )
}

export default ButtonFrotaBank
