import React, { useEffect, useState } from "react";
import { getExtratoPontos } from "../../services/fetchService";
import Spacer from "../../shared/Components/Spacer";
import AuthError from "../../shared/Components/AuthError";
import ExtratoResgateContainer from "./Components/ExtratoResgateContainer";
import "./styles.css";
import { getCookieValue } from "../../shared/utils/cookieService";
import Loading from "../../shared/Components/Loading";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate, useLocation } from 'react-router-dom';

function ExtratoResgate() {
  const navigate = useNavigate();
  const location = useLocation();

  const [extrato, setExtrato] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [authError, setAuthError] = useState(false);

  const token = getCookieValue('authToken');
  const cpf = getCookieValue('user');

  useEffect(() => {
    async function getData() {
      try {
        setIsLoading(true);
        await getExtratoPontos(cpf, token)
          .then((extratoResp) => {
            if (extratoResp.status === 200) {
              setExtrato(extratoResp.data);
            }
          })
          .catch((err) => setAuthError(true));
      } catch (error) {
        setAuthError(true);
        console.log(error);
        alert("Ocorreu um erro, tente novamente mais tarde!");
      } finally {
        setIsLoading(false);
      }
    }
    getData();
  }, [cpf, token]);

  return (
    <>
      {!authError ? (
        isLoading ? (
          <Loading />
        ) : (
          <div className="container2">
            {extrato.length === 0 ? (
              <div style={{ marginTop: "20px" }}>
                Ainda não há resgates realizados :({" "}
              </div>
            ) : (
              extrato.map((extrato) => (
                <ExtratoResgateContainer
                  key={extrato.dta_resgate}
                  extrato={extrato}
                />
              ))
            )}
            <Spacer />

            <button id="btn-anterior" onClick={() => navigate('/trocarPontos', { state: location.state })}>
              <AiOutlineLeft className="icon-voltar" />Anterior
            </button>
          </div>
        )
      ) : (
        <AuthError />
      )}
    </>
  );
}

export default ExtratoResgate;
