import React, { useEffect, useState } from "react";
import HomeIndicacao from "../HomeIndicacao";
import CadastroPegaPontos from "../CadastroPegaPontos";
import {
  fetchAutorization,
  getMembroPegapontos,
} from "../../services/fetchService";
import { useLocation } from "react-router-dom";
import AuthError from "../../shared/Components/AuthError";
import { setCookie } from "../../shared/utils/cookieService";
import Loading from "../../shared/Components/Loading";

function Wrapper() {
  const [isMembro, setIsMembro] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [authError, setAuthError] = useState(false);

  function useQuery() {
    const location = useLocation();
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  const cpf = query.get("cpf");
  const tokenVizir = query.get("token");

  useEffect(() => {
    async function getData() {
      try {
        await fetchAutorization(tokenVizir).then(
          async (authResp) => {
            const tokenAuth = authResp.data.token;
            setCookie("user", cpf);
            setCookie("authToken", tokenAuth);
            setCookie("tokenv", tokenVizir);

            await getMembroPegapontos(cpf, tokenAuth).then(
              (resp) => {
                if (resp.data.membro !== null) {
                  setIsMembro(true);
                }
              },
              () => setAuthError(true)
            );
          },
          () => setAuthError(true)
        );
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    getData();
  }, [cpf, tokenVizir]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {authError === false ? (
            isMembro ? (
              <HomeIndicacao />
            ) : (
              <CadastroPegaPontos />
            )
          ) : (
            <AuthError />
          )}
        </>
      )}
    </>
  );
}

export default Wrapper;
