import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Spacer from '../../../../shared/Components/Spacer'
import './styles.css'
import { useNavigate } from 'react-router'
import ButtonFrotaBank from '../../../../shared/Components/ButtonFrotaBank'

function ConvidadoContainer({ indicacao, val_pegapontos, pontos }) {
  const navigate = useNavigate()
  const [indicacaoStatus, setIndicacaoStatus] = useState('')
  const [dataIndicacao, setDataIndicacao] = useState('00/00/0000')
  const [timeIndicacao, setTimeIndicacao] = useState('00:00')

  const traducao = {
    statusTraducao: {
      rescued: 'Disponível no Pegapontos',
      confirmed: 'Confirmado',
      pending: 'Em espera',
    },
    statusButtonColor: {
      'Em espera': '#BDBDBD',
      Confirmado: '#4AB8A8',
      'Disponível no Pegapontos': '#037983',
    },
    statusColors: {
      'Em espera': '#C4C4C4',
      Confirmado: '#037983',
      'Disponível no Pegapontos': '#037983',
    },
    buttonText: {
      'Em espera': 'Não abriu a conta',
      Confirmado: 'Abriu a conta',
      'Disponível no Pegapontos': 'Ver detalhes',
    },
    onClick: {
      'Em espera': () => navigate('/emEspera'),
      Confirmado: () => navigate('/saldoEnviado', {
        date: dataIndicacao,
        time: timeIndicacao,
        val_resgate: parseFloat(pontos),
        val_pegapontos,
      }),
      'Disponível no Pegapontos': () => navigate('/recompensaPontos', {
        date: dataIndicacao,
        time: timeIndicacao,
      }),
    },
  }

  useEffect(() => {
    setIndicacaoStatus(traducao.statusTraducao[indicacao.status])
    setDataIndicacao(moment(indicacao.data).format('DD/MM/yyyy'))
    setTimeIndicacao(moment(indicacao.data).format('hh:mm'))
  }, [traducao.statusTraducao, indicacao.status, indicacao.data])

  return (
    <div className="convidado-geral-container">
      <div className="content-container">
        <div className="convidado-data">
          <label className="indicacao-nome">
            {indicacao.nome === '' ? 'Sem nome' : indicacao.nome.slice(0, 24)}
          </label>
          <Spacer margin="1px" />
          <div
            style={{
              color: traducao.statusColors[indicacaoStatus],
              fontSize: '12px',
            }}
          >
            {indicacaoStatus}
          </div>
          <div style={{ color: '#C4C4C4', fontSize: '1rem' }}>
            Data atualização: {dataIndicacao}
          </div>
        </div>
        <div className="right-col">
          <ButtonFrotaBank
            onClick={traducao.onClick[indicacaoStatus]}
            disableClick={true}
            className="btn-situacao"
            bgColor={traducao.statusButtonColor[indicacaoStatus]}
            title={traducao.buttonText[indicacaoStatus]}
            fontSize="1rem"
            mini
          ></ButtonFrotaBank>
        </div>
      </div>
    </div>
  )
}

export default ConvidadoContainer
