import React from 'react';
import { useLocation } from 'react-router-dom';
import DinheiroBonusPage from './Components/DinheiroBonus/index';
import PontosBonusPage from './Components/PontosBonus/index';

function BonusIndicacao() {
  
  function useQuery() {
      return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  var bonus = query.get('bonus');
  return (
    <>
    { 
    bonus === 'pontos' ?
     <PontosBonusPage /> :
     <DinheiroBonusPage />
    }
    </>
  
  );
}

export default BonusIndicacao;