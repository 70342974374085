import React from 'react';
import './styles.css';

function AuthError() {

  return (
    <div className="error-auth">
      Estamos em manutenção!
    </div>
  );
}

export default AuthError;