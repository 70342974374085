import React from 'react'
import ImagemEsperar from '../../../../assets/esperar.svg'
import './style.css'

export default function TelaSemIndicacao() {
  return (
    <div className="container">
      

      <div className="title2 titlePage">Aguarde alguns minutos para convidar!</div>
      

      <img
        style={{ width: '40%', margin: '0 30px 10px 30px' }}
        src={ImagemEsperar}
        alt="moedas"
      ></img>

      <div style={{ marginTop: '40px' }} className="title2">
        <p>
          Seu link de indicação ainda não está pronto. Aguarde alguns minutos e
          tente novamente.
        </p>
      </div>
    </div>
  )
}
