import { api } from "./api";

export async function fetchAutorization(token) {
  try {
    const autorizationData = {
      tokenvizir: token,
    };

    const response = await api.post("/gerar/tokenvizir", autorizationData);
    return response;
  } catch (error) {
    console.log(error);
    throw Error("Ocorreu um erro, tente novamente mais tarde");
  }
}

export async function fetchEmbaixadorData(cpf, token) {
  try {
    const authHeaderConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const dataResponse = await api.get(
      `/obter/dados_embaixador?cpf=${cpf}`,
      authHeaderConfig
    );
   
    return dataResponse;
  } catch (error) {
    throw Error("Ocorreu um erro, tente novamente mais tarde");
  }
}

export async function getMembroPegapontos(cpf, token) {
  try {
    const authHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const ppResponse = await api.get(`/membro?cpf=${cpf}`, authHeader);
    return ppResponse;
  } catch (error) {
    console.log(error);
    throw Error("Ocorreu um erro, tente novamente mais tarde");
  }
}

export async function getSaldoMembroPegaPontos(cpf, token) {
  try {
    const authHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const saldoPegapontosResponse = await api.get(
      `/saldo/membro?cpf=${cpf}`,
      authHeader
    );

    return saldoPegapontosResponse;
  } catch (error) {
    console.log(error);
    throw Error("Ocorreu um erro, tente novamente mais tarde!");
  }
}

export async function cadastrarMembroPegaPontos(membroData, token) {
  try {
    const authHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const pegaPontosResponse = await api.post(
      "/cadastro/membro",
      membroData,
      authHeader
    );
    return pegaPontosResponse.data;
  } catch (error) {
    console.log(error);

    throw Error("Tente novamente mais tarde!");
  }
}

export async function logarMembroPegapontos(loginData,token) {
  try {
    const authHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const loginMembroResponse = await api.post(
      "/login/membro",
      loginData,
      authHeader
    );
    return loginMembroResponse.data;
  } catch (error) {
    console.log(error);
    throw Error("Tente novamente mais tarde!");
  }
}

export async function getExtratoPontos(cpf, token) {
  try {
    const authHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = {
      cpf,
    };

    const response = await api.post("/extrato/pontos", data, authHeader);

    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function trocarPontos(data, token) {
  const authHeader = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  let result;
  await api.post("/trocar/pontos", data, authHeader)
  .then((response) => {
    result = response;
  })
  .catch((error) => {
    result = error.response;
  });
  return result;
}

export async function GetLimitesResgate(token, cpf){
    const authHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }

    const limitesResgateResponse = await api.get(
      `/resgates/limites?cpf=${cpf}`,
      authHeader
    )

    return limitesResgateResponse;
}